import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function AgentFooter(props) {
  return(
    <Box component='footer' sx={{
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#d4d4d4',
      padding: '12px',
      borderTop: 1,
      borderColor: 'rgba(87,29,57,0.25)',
    }}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{
            height: '60px',
            verticalAlign: 'center',
          }}
          component='img'
          alt={props.agentName}
          src={props.agentPicURL}
        />
        <Box sx={{ paddingLeft: '8px'}}>
          <Typography><strong>{props.agentName}, REALTOR®</strong></Typography>
          <Typography>{props.agentBrokerName}</Typography>
        </Box>
      </Box>
      <Box sx={{
          height: '60px',
          verticalAlign: 'center',
          paddingTop: '8px',
          paddingBottom: '8px',
        }}
        component='img'
        alt={props.agentBrokerName}
        src={props.agentBrokerLogoURL}
      />
    </Box>
  );
}
