import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const errorTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#571D39',
    },
    secondary: {
      main: '#fe000c',
    }
  }
})

export default function ErrorPage(props) {
  return(
    <ThemeProvider theme={errorTheme}>
    <CssBaseline />
    <Box sx={{
      display: 'grid',
      height: '100vh',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto 1fr auto',
      gridTemplateAreas: `
        "top"
        "content"
        "footer"
      `
    }} >
      <Box layoutArea='top' sx={{ height: '1em', backgroundColor: '#571D39'  }} />
      <Box layoutArea='content'
           sx={{ fontWeight: 'bold', textAlign: 'center', alignSelf: 'center' }}>
          <Typography variant='h5' color='primary' sx={{ fontWeight: 'bold' }}>Something went wrong!</Typography><br />
          <SentimentDissatisfiedIcon fontSize='large' /><br />
          It's not you. It's us.<br />
          We will get on fixing this ASAP.
      </Box>
      <Box layoutArea='footer' sx={{ height: '1em', backgroundColor: '#571D39'  }} />
    </Box>
    </ThemeProvider>
  );
}
