import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function FeedbackAppHeader(props) {

  return(
      <Box component='header' sx={{
        gridArea: props.layoutArea,
        position: 'sticky',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      /* backgroundColor: 'primary.main', */
        borderBottom: 1,
        borderColor: 'rgba(87,29,57,0.25)',
        paddingLeft: '12px',
        paddingRight: '12px',
        backgroundColor: 'primary.main',
        /* boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'  */
      }}>
        <Typography variant="h6" component='h1' sx={{ color: 'white'}}>{props.propStreetAddr}</Typography>
        <Box sx={{
            height: '70px',
            verticalAlign: 'center',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}
          component='img'
          src={props.propPicURL}
          alt={props.propStreetAddr}
        />
      </Box>
  );
}
