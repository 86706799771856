import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import FeedbackApp from './FeedbackApp';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './ErrorPage';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
         <Route path="*" element={<Navigate to="/error" />} />
        <Route path="/feedback" element={ <FeedbackApp /> } />
        <Route path="/error" element={ <ErrorPage />  } />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
