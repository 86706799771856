import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

/* MUI imports */
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

/* App Components Import */
import FeedbackAppHeader from './feedback/FeedbackAppHeader';
import FeedbackAppFooter from './feedback/FeedbackAppFooter';
import FeedbackForm from './feedback/FeedbackForm';
import FeedbackThankYou from './feedback/FeedbackThankYou';
import FeedbackAppWait from './feedback/FeedbackAppWait';

const ohHiClientTheme = createTheme({
 palette: {
   type: 'light',
   primary: {
     main: '#571D39',
   },
   secondary: {
     main: '#fe000c',
   },
 },
 typography: {
  h1: {
    fontWeight: 700,
    fontSize: '2rem',
    letterSpacing: '-0.04rem'
  },
},
});

/* Check if string is valid UUID */
function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
}

const stateInitializing = 0;
const stateLoading = 1;
const stateFeedback = 2;
const stateThankYou = 3;

function FeedbackMainPanel(props) {
  switch(props.state) {
    case stateLoading:
    case stateInitializing:
      return <FeedbackAppWait />
    case stateFeedback:
      return <FeedbackForm onSubmit={ props.onSubmit }/>
    case stateThankYou:
      return <FeedbackThankYou agentWebsiteURL="https://enelyrealtor.com/kleinliving"/>
    default:
      return <Navigate to="/error" />
  }
}

export default function FeedbackApp() {
  let defaultEventInfo = {
    agentBrokerLogoURL: "",
    agentBrokerName: "",
    agentName: "",
    agentPicURL: "",
    agentWebsiteURL: "",
    propPicURL: "",
    propStreetAddr: ""
  };
  const [feedbackAppState, setFeedbackAppState] = useState(stateInitializing);
  const [ohEventId, setOHEventId] = useState(null);
  const [ohFeedbackToken, setOHFeedbackToken] = useState(null);
  const [ohEventInfo, setOHEventInfo] = useState(defaultEventInfo);
  const api_url = "https://3sh3qobwp8.execute-api.us-east-1.amazonaws.com/events";
  var event_id;

  function submitFeedback(f) {
    const url = api_url + '/' + ohEventId;

    console.log('calling '+url);
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(f),
      headers: {
        'Content-Type': 'application/json',
        'X-OHF-Token': ohFeedbackToken
      },
      referrerPolicy: 'strict-origin',
      cache: 'no-cache'
    })
    .then(response => response.json())
    .then(data => console.log(data))
    setFeedbackAppState(stateThankYou);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const event_id = queryParams.get('id');
    console.log('id='+event_id);
    console.log(checkIfValidUUID(event_id));

    const url = api_url + '/' + event_id
    fetch(url, { mode: 'cors' })
    .then(response => {
      setOHEventId(event_id);
      setOHFeedbackToken(response.headers.get('x-ohf-token'));
      return response.json();
    })
    .then(data => { setOHEventInfo(data); setFeedbackAppState(stateFeedback); })
  },[]);

  return (
    <ThemeProvider theme={ohHiClientTheme}>
    <CssBaseline />
    <Box sx={{
      display: 'grid',
      height: '100vh',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto 1fr auto',
      gridTemplateAreas: `
        "topnav"
        "content"
        "footer"
         `,
    }} className='App'>
        <FeedbackAppHeader
          layoutArea='topnav'
          propStreetAddr={ohEventInfo.propStreetAddr}
          propPicURL={ohEventInfo.propPicURL}
        />
        <FeedbackMainPanel state={feedbackAppState} onSubmit={ submitFeedback }/>
        <FeedbackAppFooter
            layoutArea='footer'
            agentName={ohEventInfo.agentName}
            agentPicURL={ohEventInfo.agentPicURL}
            propPicURL={ohEventInfo.propPicURL}
            propStreetAddr={ohEventInfo.propStreetAddr}
            agentBrokerName={ohEventInfo.agentBrokerName}
            agentBrokerLogoURL={ohEventInfo.agentBrokerLogoURL}
        />
    </Box>
    </ThemeProvider>
  );
}
