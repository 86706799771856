import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Input from '@mui/material/Input';
import { visuallyHidden } from '@mui/utils';

import SendIcon from '@mui/icons-material/Send';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#571D39',
  },
  '& .MuiRating-iconHover': {
/*    color: '#8a2d5a', */
    color: '#773d59',
  },
});

const OHRating = (props) => {
  return(
    <StyledRating size='large' {...props} />
  );
}

export default function FeedbackForm(props) {
  const nameField = useRef(null);
  const feedbackForm = useRef(null);

  const [curbAppealValue, setCurbAppealValue] = useState(0);
  const [locationValue, setLocationValue] = useState(0);
  const [amenitiesValue, setAmenitiesValue] = useState(0);
  const [conditionValue, setConditionValue] = useState(0);
  const [floorplanValue, setFloorplanValue] = useState(0);
  const [priceValue, setPriceValue] = useState(0);
  const [ohLeadValue, setOHLeadValue] = useState("none-selected");
  const goto = useNavigate();

  const handleOHLeadValue = (event, newValue) => {
    setOHLeadValue(newValue);
  };

  function handleSubmit(e) {
    e.preventDefault();
    var feedback_values = {};
    const data = new FormData(feedbackForm.current)
    data.forEach( (v,k)=>feedback_values[k]=v  );
    props.onSubmit(feedback_values);
  }

/*  const api_url = "http://localhost:3001/events"; */

  return(
      <Container sx={{ padding: 2, overflow: 'auto' }}>
        <form ref={feedbackForm} onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            required
            name="full-name"
            label="Name"
            inputRef={nameField}
            autoComplete='name'
          />
          <TextField
            fullWidth
            name="email"
            type="email"
            label="Email"

            autoComplete='email'
          />
          <TextField
            fullWidth
            name="phonenumber"
            type="tel"
            label="Phone number"

            autoComplete="tel"
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color='primary.main' component="legend">
              Curb Appeal
            </Typography>
            <OHRating
              value={curbAppealValue}
              onChange={(event, newValue) => { setCurbAppealValue(newValue); }}
              name="curbappeal-value"
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color='primary.main' component="legend">
              Location
            </Typography>
            <OHRating
              value={locationValue}
              onChange={(event, newValue) => { setLocationValue(newValue); }}
              name="location-value"
            />
            </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color='primary.main' component="legend">
              Amenities
            </Typography>
            <OHRating
              value={amenitiesValue}
              onChange={(event, newValue) => { setAmenitiesValue(newValue); }}
              name="amenities-value"
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color='primary.main' component="legend">
              Condition
            </Typography>
            <OHRating
              value={conditionValue}
              onChange={(event, newValue) => { setConditionValue(newValue); }}
              name="condition-value"
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color='primary.main' component="legend">
              Floor plan
            </Typography>
            <OHRating
              value={floorplanValue}
              onChange={(event, newValue) => { setFloorplanValue(newValue); }}
              name="floorplan-value"
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color='primary.main' component="legend">
              Price
            </Typography>
            <OHRating
              value={priceValue}
              onChange={(event, newValue) => { setPriceValue(newValue); }}
              name="price-value"
            />
          </Box>

          <Typography>
          How did you hear about this Open House?
          </Typography>
          <ToggleButtonGroup
            color="primary"
            value={ohLeadValue}
            exclusive
            onChange={handleOHLeadValue}
            orientation='vertical'
          >
            <Input sx={visuallyHidden} name="oh-lead-source" value={ohLeadValue} />
            <ToggleButton value="ohsign">Open House Signs</ToggleButton>
            <ToggleButton value="fb">Facebook</ToggleButton>
            <ToggleButton value="har">HAR</ToggleButton>
            <ToggleButton value="zillow">Zillow</ToggleButton>
            <ToggleButton value="other">Other</ToggleButton>
          </ToggleButtonGroup>

          <TextField
            fullWidth
            name="likes-most-source"
            label="What did you like the MOST?"
            margin="normal"
          />
          <TextField
            fullWidth
            name="likes-least-source"
            label="What did you like the LEAST?"
            margin="normal"
          />

          <FormControlLabel control={<Checkbox name="email-copy" color="primary" size="large"/>} label="Email me a copy of my feedback" />

          <Typography variant='h5' color='primary.main'>
            Let's keep in touch!
          </Typography>
          <TextField
            fullWidth
            name="street-address"
            label="Street Address"
            helperText="ex. 1645 Louetta Rd #300"
            autoComplete="street-address"
          />
        <TextField
          fullWidth
          name="city"
          label="City"

          autoComplete="address-level2"
        />
        <TextField
          fullWidth
          name="state"
          label="State"
          autoComplete="address-level1"
        />
        <TextField
          fullWidth
          type='number'
          name="zip"
          label="Zip Code"

          autoComplete="postal-code"
        />

        <Button name="submit-button" type='submit' variant='contained' size='large' endIcon={<SendIcon />}>
          Share Feedback
        </Button>

        </Stack>
        </form>
        </Container>
  );
}
