import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

export default function FeedbackThankYou(props) {
  return(
    <Container sx={{marginTop: 'auto', marginBottom: 'auto'}}>
      <Typography align='center' variant='h6' sx={{ fontWeight: 'bold' }}>Thank you for your feedback.</Typography>
      <Typography align='center'>
        Want to know more about the best local eats and business in the Spring-Klein-Tomball area?<br />
        Visit us at Klein Living!<br /><a href={ props.agentWebsiteURL }>{props.agentWebsiteURL}</a>
      </Typography>
    </Container>
  );
}
