import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function FeedbackAppWait() {
  return(
    <Box sx={{ display: 'flex', margin: 'auto', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="primary" />
    </Box>
  );
}
